<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Berikut adalah <b>Daftar Stock Opname</b> yang ada di Klinik IONA
      </div>
      <b-button squared variant="success" @click="$router.push('/stock-opnames/add')" v-if="btn"
        >Tambah</b-button
      >
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <div class="d-flex align-items-center justify-content-end mt-n3">
              <!-- <div class="col-md-3">
                <treeselect
                  v-model="filter.blood_type_id"
                  :multiple="false"
                  :options="blood_type"
                  placeholder="Cari Berdasar Gol. Darah"
                  @select="filterByBloodType"
                />
              </div> -->
              <div class="col-md-3">
                <!-- <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.name"
                    placeholder="Cari "
                    @keyup="filterByName"
                  ></b-form-input>
                </b-input-group> -->
              </div>
            </div>
            <b-table striped hover :items="items" :fields="fields">
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '20%' : '' }"
                />
              </template>
              <template #cell(status)="data">
                <span v-if="data.item.status_id == 0" ><i class="fas fa-circle text-danger"></i> {{data.item.status}}</span>
                <span v-if="data.item.status_id == 1" ><i class="fas fa-circle text-success"></i> {{data.item.status}}</span>
              </template>
              <template #cell(count)="data">
                <span>{{data.item.count}} item</span>
              </template>
              <template #cell(actions)="data">
                <b-button
                  size="sm"
                  class="mr-1 btn-outline-info"
                  v-b-tooltip.hover
                  title="Cetak"
                  @click="
                    $router.push({ path: '/stock-opnames/print/' + data.item.id })
                  "
                >
                  <i class="fas fa-print px-0"></i>
                </b-button>
                <b-button
                  size="sm"
                  class="mr-1 btn-info"
                  v-b-tooltip.hover
                  title="Detail"
                  @click="
                    $router.push({ path: '/stock-opnames/detail/' + data.item.id })
                  "
                >
                  <i class="fas fa-eye px-0"></i>
                </b-button>
                <b-button
                  size="sm"
                  class="mr-1 btn-success"
                  v-b-tooltip.hover
                  title="Edit"
                  @click="
                    $router.push({ path: '/stock-opnames/edit/' + data.item.id })
                  "
                  v-if="data.item.status_id == 0 && btn == true" 
                >
                  <i class="fas fa-edit px-0"></i>
                </b-button>
                <b-button
                    size="sm"
                    class="btn-danger"
                    v-b-tooltip.hover
                    title="Hapus"
                    @click="btnDeleteOnClick(data.item.id)"
                    v-if="data.item.status_id == 0 && btn == true" 
                  ><i class="fas fa-trash px-0"></i></b-button>
              </template>
            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @page-click="pageOnClick"
              class="mt-4"
            ></b-pagination>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    Card,
  },

  data() {
    return {
      // Filter
      filter: {
        name: "",
        blood_type_id: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "checking_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "employee_name",
          label: "Petugas",
          sortable: true,
        },
        {
          key: "count",
          label: "Jml. Item",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      blood_type: [],
      // User Access
      btn: false,
    };
  },

  methods: {
    pageOnClick(page) {
      this.currentPage = page;
      this.pagination();
    },

    async btnDeleteOnClick(id) {
      // Delete Data
      let result = await module.delete("stock-opnames/" + id);
      // If Deleted
      if (result) {
        this.pagination()
      }
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}`;
      let response = await module.paginate(
        `stock-opnames`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination
      this.totalRows = pagination.total
      
      let a, b
      b = this.perPage * (this.currentPage - 1) + 1  
      for(a = 0; a < response.data.length; a++){
        response.data[a].number = b + a
      }
      this.items = response.data;
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("stock-opnames/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    

    filterByName() {
      // this.pagination();
      this.generalFilter()
    },

    filterByBloodType(evt) {
      this.filter.blood_type_id = evt.id
      this.generalFilter()
    },

    generalFilter(){
      this.pagination()
    },

    setActiveButton(){
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)

      let a
      for(a = 0; a < access_right.length; a++){
        if(access_right[a] == "9010"){
          this.btn = true
        }
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Stock Opname", route: "/stock-opnames" },
      { title: "Daftar Stock Opname" },
    ]);

    this.pagination();
    // this.getBloodTypeOption();
    this.setActiveButton()
  },

  // watch: {
  //   'filter.blood_type_id': function (newVal, oldVal) {
  //     if (typeof newVal === 'undefined') {
  //       this.filter.blood_type_id = ''
  //       this.generalFilter()
  //     }
  //   }
  // },
};
</script>

<style>
</style>